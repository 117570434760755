/* You can add global styles to this file, and also import other style files */
@import "assets/css/styles.scss";
@import url("https://s3-us-west-1.amazonaws.com/ogr-icons-font/style.ogr_fonts.css");

.index-hidden {
    display: none;
}

.using-mouse {
    button:focus,
    button:active:focus {
        outline: none;
    }
}
