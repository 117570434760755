// Variables por defecto
// mover todas las variables al clonador

// Paleta de colores ---- default
$background-color-profile1: white !default;
$background-color-navbar-profile1: white !default;
$font-color-navbar-profile1: black !default;
$font-color-hover-navbar-profile1: black !default;
$responsive-toggle-button-background-color: white !default;
$responsive-toggle-button-font-color: black !default;
$color-omitidas: #c0c0c0 !default;
$color-correctas: #9ec74d !default;
$color-incorrectas: #ea6767 !default;

// Arreglos ----- default
$asignatura-colors: () !default;
$asignatura-font-colors: () !default;
$palette-colors: (
    "primary": #ffffff,
    "secondary": #ffffff,
    "lighterFontColor": #d3d3d3
) !default;
$notificacion-colors: () !default;
$cui-showcasebox-colors: () !default;

// Variables de tamaños
$footer-push-height: 150px !default;

// Lista de asignaturas
$asignatura-list: "matematica" "lenguaje" "historia" "ciencias" !default;

$custom-font-family3: "Sans serif" !default;
$custom-font-family4: "Sans serif" !default;

//####### Variables base #######

// VARIABLES CLONADOR
// TODO: Borrar comentarios
//COLORES

// ------- Titulo base
// $base-titlebar_color: #333333 !default;
// $base-titlebar_subtitle_color: #4f585d !default;
// $base-subtitle_color: #333333 !default;
// $base-subtitle_underline_color: #cccccc !default;

// // ------- Botones base
// $base-primary_button_background_color: #353535 !default;
// $base-primary_button_background_color_hover: darken(#353535, 10%) !default;
// $base-primary_button_font_color: white !default;
// $base-primary_button_font_color_hover: white !default;

// $base-cancel_button_background_color: #353535 !default;
// $base-cancel_button_background_color_hover: darken(#353535, 10%) !default;
// $base-cancel_button_font_color: white !default;
// $base-cancel_button_font_color_hover: white !default;

// $base-secondary_button_background_color: #353535 !default;
// $base-secondary_button_background_color_hover: darken(#353535, 10%) !default;
// $base-secondary_button_font_color: white !default;
// $base-secondary_button_font_color_hover: white !default;

// // ------- Menu item base (calugas)
// $base-menu_item_background_left_color: #353535 !default;
// $base-menu_item_background_left_font_color: white !default;
// $base-menu_item_title_color: #353535 !default;
// $base-menu_item_title_underline_color: #cccccc !default;

// // ------- Showcasebox base
// $base-showcasebox_icon_color: #353535 !default;
// $base-showcasebox_title_color: #353535 !default;
// $base-showcasebox_title_background_color: white !default;

// // ------- Navbar base

// $base-navbar_background_color: white !default;
// $base-navbar_background_color_hover: #e6e6e6 !default;
// $base-navbar_font_color: #333333 !default;
// $base-navbar_font_color_hover: #333333 !default;
// $base-navbar_menu_btn_background_color: transparent !default;
// $base-navbar_menu_btn_background_color_hover: transparent !default;
// $base-navbar_font_color_menu_icon: #333333 !default;
// $base-navbar_font_color_menu_icon_hover: #333333 !default;
// $base-navbar_avatar_background_color: #a5adb3 !default;

// // ------- Topmenu base
// $base-topmenu_background_color: white !default;
// $base-topmenu_background_color_hover: #e6e6e6 !default;
// $base-topmenu_font_color: #333333 !default;
// $base-topmenu_font_color_hover: #333333 !default;

// ------- menu horizontal base
// $base-menu_horizontal_background_color: white !default;
// $base-menu_horizontal_background_color_hover: #333333 !default;
// $base-menu_horizontal_font_color: #333333 !default;
// $base-menu_horizontal_font_color_hover: white !default;

// // ------- menu showcasebox base
// $base-menu_showcasebox_background_color: white !default;
// $base-menu_showcasebox_background_color_hover: #e6e6e6 !default;
// $base-menu_showcasebox_font_color: #333333 !default;
// $base-menu_showcasebox_font_color_hover: #333333 !default;
// $base-menu_showcasebox_icon_font_color: white !default;
// $base-menu_showcasebox_icon_background_color: #333333 !default;
// $base-menu_showcasebox_with_arrow: true !default;

// // ------- Footer base
// $base-footer_background_color: #353535 !default;
// $base-footer_font_color: white !default;
// $base-footer_font_color_hover: white !default;
// $base-footer_social_media_background_color: transparent !default;
// $base-footer_social_media_background_color_hover: darken(#353535, 10%) !default;

// ------- Landing base
// $base-landing_subtitle_font_color: white !default;
// $base-landing_footer_background_color: #353535 !default;

//NO COLORES
// :root {
// --custom-font-family1: "Sans serif";
// --custom-font-family2: "Sans serif";

// --base-titlebar_size: 26px;
// --base-titlebar_subtitle_size: 18px;

// --base-navbar_height: 50px;
// --base-navbar_avatar_size: 40px;
// --base-navbar_logo_height: 30px;

// --base-menu_horizontal_border_marker: false;
// --base-menu_horizontal_with_bottom_border: false;
// --base-menu_horizontal_no_icon: false;
// --base-menu_showcasebox_rounded: false;
// }

// END VARIABLES CLONADOR

//TODO pasar a clonador
$base-landing_logo_max_width: 400px !default;
$base-landing_logo_footer_height: 40px !default;
$base-landing_login_align: center !default;

body.body-default {
    @if mixin-exists(body_default) {
        @include body_default;
    }
}
/* Profile 1: docentes */
body.profile1,
.body_profile1 {
    @if mixin-exists(body_profile1) {
        @include body_profile1;
    }
}
/* Profile 2: estudiantes */
body.profile2 {
    @if mixin-exists(body_profile2) {
        @include body_profile2;
    }
}
body.profile3 {
    @if mixin-exists(body_profile3) {
        @include body_profile3;
    }
}

.btn_base_1 {
    @if mixin-exists(btn_base_1) {
        @include btn_base_1;
    }
}

.btn_base_2 {
    @if mixin-exists(btn_base_2) {
        @include btn_base_2;
    }
}

.btn_base_3 {
    @if mixin-exists(btn_base_3) {
        @include btn_base_3;
    }
}

.btn_base_4 {
    @if mixin-exists(btn_base_4) {
        @include btn_base_4;
    }
}

.btn-table {
    @if mixin-exists(small-table-btn-with-only-icon) {
        @include small-table-btn-with-only-icon;
    }
}

.small-round-btn {
    @if mixin-exists(small-round-social-btn) {
        @include small-round-social-btn;
    }
}

//####### Títulos #######
.custom_title_1,
.custom_title {
    @include custom_title_1;
}

.custom_title_2 {
    @include custom_title_2;
}

.cui-main-headline {
    @if mixin-exists(cui-main-headline) {
        @include cui-main-headline;
    }
}

.cui-title-headline {
    @if mixin-exists(cui-title-headline) {
        @include cui-title-headline;
    }
}

.h3_sub_title_1 {
    @if mixin-exists(cui-section-headline_item) {
        @include cui-section-headline_item;
    } @else {
        //retrocompatibilidad
        @include h3_sub_title_1;
    }
}

.h3_sub_title_2 {
    @include h3_sub_title_2;
}

.h3_sub_title_3 {
    @include h3_sub_title_3;
}

.h3_sub_title_9c3 {
    @include h3_sub_title_base(#9c3);
}

.h4_sub_title_1 {
    @include h4_sub_title_1;
}

//####### Tablas #######
table.tabla_estadisticas {
    @include simple_table_statistics;
}

table.tabla_ensayos,
table.tabla_planes_personales {
    @include table_instruments;
}

//####### Carousel #######
.carousel_style_1 {
    @include carousel_style_1;
}

//####### Showcase Box #######
.cui-showcasebox-theme-default {
    @if mixin-exists(cui_showcasebox_style_1) {
        @include cui_showcasebox_style_1();
    } @else if mixin-exists(showcasebox_style_1) {
        //retrocompatibilidad
        @include showcasebox_style_1();
    } @else {
        @include cui_showcasebox_base_style;
    }
}

@if variable-exists(cui-showcasebox-colors) {
    @each $key, $color in $cui-showcasebox-colors {
        .cui-showcasebox-theme-#{$key} {
            @if mixin-exists(cui_showcasebox_style_1_with_color) {
                @include cui_showcasebox_style_1_with_primary_color(cui-showcasebox-colors($color));
            } @else {
                //retrocompatibilidad
                @include showcasebox_style_1_with_primary_color(palette-color($key));
            }
        }
    }
} @else {
    //retrocompatibilidad
    .cui-showcasebox-theme-noticias {
        @include showcasebox_style_1_with_primary_color(palette-color("noticias"));
    }
    .cui-showcasebox-theme-blog {
        @include showcasebox_style_1_with_primary_color(palette-color("blog"));
    }
    .cui-showcasebox-theme-establecimientos {
        @include showcasebox_style_1_with_primary_color(palette-color("establecimientos"));
    }
    .cui-showcasebox-theme-carreras {
        @include showcasebox_style_1_with_primary_color(palette-color("carreras"));
    }
    .cui-showcasebox-theme-logros {
        @include showcasebox_style_1_with_primary_color(palette-color("logros"));
    }
}

.showcasebox_style_1 {
    @include showcasebox_style_1;
}

.showcasebox_style_1_carreras {
    @include showcasebox_style_1_with_primary_color(palette-color("carreras"));
}

.showcasebox_style_1_primary {
    @include showcasebox_style_1_with_primary_color(palette-color("primary"));
}

.showcasebox_style_1_establecimientos {
    @include showcasebox_style_1_with_primary_color(palette-color("establecimientos"));
}

.showcasebox_style_1_blog {
    @include showcasebox_style_1_with_primary_color(palette-color("blog"));
}

.showcasebox_style_1_ranking {
    @include showcasebox_style_1_with_primary_color(#e6b74c);
}

.showcasebox_style_2 {
    @include showcasebox_style_2;
}

.showcasebox_style_user {
    @include showcasebox_style_user;
}

%modal_style {
    @if mixin-exists(modal_style) {
        @include modal_style;
    }
}

//####### Menu Item Box #######
.menu_item_box {
    @if mixin-exists(menu_item_box_default_style) {
        @include menu_item_box_default_style;
    }
}

//####### Menu Item Box #######
nav.menu-asignaturas {
    @if mixin-exists(menu_asignaturas_platform_style) {
        @include menu_asignaturas_platform_style;
    }
}

//####### Estilos adicionales (provisorio) #######
nav.navbar {
    @include landing_menu_top;
}

.numero_pregunta_style {
    @if mixin-exists(numero_pregunta_style_item) {
        @include numero_pregunta_style_item;
    }
}

:root {
    --background-color-profile1: #{$background-color-profile1};
    --background-color-navbar-profile1: #{$background-color-navbar-profile1};
    --font-color-navbar-profile1: #{$font-color-navbar-profile1};
    --font-color-hover-navbar-profile1: #{$font-color-hover-navbar-profile1};
    --responsive-toggle-button-background-color: #{$responsive-toggle-button-background-color};
    --responsive-toggle-button-font-color: #{$responsive-toggle-button-font-color};
    --color-omitidas: #{$color-omitidas};
    --color-correctas: #{$color-correctas};
    --color-incorrectas: #{$color-incorrectas};

    @each $palette, $color in $palette-colors {
        --palette-colors-#{$palette}: #{$color};
        @include extract-hsl(palette-colors-#{$palette}, $color);
    }

    @each $notificacion, $color in $notificacion-colors {
        --notificacion-colors-#{$notificacion}: #{$color};
        @include extract-hsl(notificacion-colors-#{$notificacion}, $color);
    }

    @each $cui-showcasebox, $color in $cui-showcasebox-colors {
        --cui-showcasebox-colors-#{$cui-showcasebox}: #{$color};
        @include extract-hsl(cui-showcasebox-colors-#{$cui-showcasebox}, $color);
    }

    --footer-push-height: #{$footer-push-height};
    --custom-font-family3: #{$custom-font-family3};
    --custom-font-family4: #{$custom-font-family4};
    --base-landing_logo_max_width: #{$base-landing_logo_max_width};
    --base-landing_logo_footer_height: #{$base-landing_logo_footer_height};
    --base-landing_login_align: #{$base-landing_login_align};

    @include extract-hsl(background-color-profile1, $background-color-profile1);
    @include extract-hsl(background-color-navbar-profile1, $background-color-navbar-profile1);
    @include extract-hsl(font-color-navbar-profile1, $font-color-navbar-profile1);
    @include extract-hsl(font-color-hover-navbar-profile1, $font-color-hover-navbar-profile1);
    @include extract-hsl(responsive-toggle-button-background-color, $responsive-toggle-button-background-color);
    @include extract-hsl(responsive-toggle-button-font-color, $responsive-toggle-button-font-color);
    @include extract-hsl(color-omitidas, $color-omitidas);
    @include extract-hsl(color-correctas, $color-correctas);
    @include extract-hsl(color-incorrectas, $color-incorrectas);
}
