@import "style.template.scss";
@import "style.platform.custom.scss";
@import "style.platform.custom.fonts.scss";
@import "style.defaults.scss";
@import "outside_loader.puntaje.scss";
@import "~angular-calendar/css/angular-calendar.css";

html {
    display: block;
    height: 100%;
    font-size: 16px;
}

puntaje {
    display: block;
    height: 100%;
}

body {
    display: block;
    height: 100%;
    @extend %modal_style !optional;
}

@include keyframes(fadingBody) {
    0%,
    80% {
        opacity: 0;
        background: white;
    }
    100% {
        opacity: 1;
    }
}

@media print {
    .print-hide {
        display: none !important;
    }

    .print-show-block {
        display: block !important;
    }

    .print-page-break-after {
        page-break-after: always;
        display: block;
    }

    .print-page-break-before {
        page-break-before: always;
        display: block;
    }

    .print-page-break-avoid {
        page-break-inside: avoid;
    }

    .print-full-width {
        width: 100% !important;
    }

    .print-force-expand {
        height: auto !important;
        overflow: auto !important;
    }
}

@page {
    size: auto;
    margin: 0.5in 0.5in 0.5in 0.5in;
}
