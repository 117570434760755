//############### Mixins ##############
//  Colección de funciones útiles de scss

$asignatura-list: "matematica" "lenguaje" "historia" "ciencias" "biologia" "fisica" "quimica" "ciencias-ciudadania"
    "educacion-ciudadana" "matematicas" "lectura_critica" "competencias_ciudadanas" "ciencias_naturales" "ingles" "mate"
    "lectura" "redaccion" "asignatura1" "asignatura2" "asignatura3" "asignatura4" "asignatura5" "Mat" "Len"
    "historia-y-ciencias-sociales" "cs-para-la-ciudadania" "ed-ciudadana";

$notificacion-list: "default" "evaluacion" "dudas" "pro" "plan-personal";

//############### Utilidad para castear string a color ##############

/// Logs an error at `$pointer` with `$string` message
/// @access private
/// @param {String} $string - error message
/// @param {Number} $pointer - pointer position
@function _throw($string, $pointer) {
    @error "ERROR::#{$pointer}::#{$string}";
}

/// Convert an hexadecimal number to a decimal number
/// @access private
/// @param {String} $string - hexadecimal value
/// @return {Number} - decimal number
@function _hex-to-dec($string) {
    $hex: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9" "a" "b" "c" "d" "e" "f";
    $string: to-lower-case($string);
    $length: str-length($string);

    $dec: 0;
    @for $i from 1 through $length {
        $factor: 1 + (15 * ($length - $i));
        $index: index($hex, str-slice($string, $i, $i));
        $dec: $dec + $factor * ($index - 1);
    }

    @return $dec;
}

/// Parses a JSON encoded number to find the integer part
/// @access private
/// @param {String} $source - JSON complete source
/// @param {Number} $pointer - current pointer
/// @throw Unexpected token $token.
/// @return {List} (new pointer, parsed number)
/// @require {function} _throw
@function _find-integer($source, $pointer) {
    $source: to-lower-case($source);
    $length: str-length($source);
    $numbers: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9";
    $result: 0;

    @while $pointer <= $length {
        $token: str-slice($source, $pointer, $pointer);
        $index: index($numbers, $token);

        @if $token == "-" {
            // do nothing
        } @else if $index {
            $result: $result * 10 + ($index - 1);
        } @else {
            @if index("e" "." " " "," "]" "}", $token) {
                @return $pointer, $result;
            }

            @return _throw("Unexpected token `" + $token + "`.", $pointer);
        }

        $pointer: $pointer + 1;
    }

    @return $pointer, $result;
}

/// Parses a JSON encoded number to find the digits
/// @access private
/// @param {String} $source - JSON complete source
/// @param {Number} $pointer - current pointer
/// @throw Unexpected token $token.
/// @return {List} (new pointer, parsed number)
/// @require {function} _throw
@function _find-digits($source, $pointer) {
    $source: to-lower-case($source);
    $length: str-length($source);
    $numbers: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9";
    $result: null;
    $runs: 1;

    @while $pointer <= $length {
        $token: str-slice($source, $pointer, $pointer);
        $index: index($numbers, $token);

        @if $token == "." {
            // @continue;
        } @else if $index and $index > 0 {
            $runs: $runs * 10;
            $result: if($result == null, ($index - 1), $result * 10 + ($index - 1));
        } @else {
            @if index("e" "." " " "," "]" "}", $token) {
                @return $pointer, if($result != null, $result / $runs, $result);
            }

            @return _throw("Unexpected token `#{$token}`.", $pointer);
        }

        $pointer: $pointer + 1;
    }

    @return $pointer, if($result != null, $result / $runs, $result);
}

/// Parses a JSON encoded number to find the exponent part
/// @access private
/// @param {String} $source - JSON complete source
/// @param {Number} $pointer - current pointer
/// @throw Unexpected token $token.
/// @return {List} - (new pointer, parsed number)
/// @require {function} _throw
@function _find-exponent($source, $pointer) {
    $source: to-lower-case($source);
    $length: str-length($source);
    $numbers: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9";
    $result: null;
    $minus: null;

    @while $pointer <= $length {
        $token: str-slice($source, $pointer, $pointer);
        $index: index($numbers, $token);

        @if $token == "e" {
            // @continue;
        } @else if $token == "-" {
            @if $minus != null {
                @return _throw("Unexpected token `-`.", $pointer);
            }
            $minus: true;
        } @else if $token == "+" {
            @if $minus != null {
                @return _throw("Unexpected token `+`.", $pointer);
            }
            $minus: false;
        } @else if $index and $index > 0 {
            $result: if($result == null, ($index - 1), $result * 10 + ($index - 1));
        } @else {
            @if index(" " "," "]" "}", $token) == null {
                @return _throw("Unexpected token `" + $token + "`.", $pointer);
            }

            @return $pointer, if($minus and $result != null, $result * -1, $result);
        }

        $pointer: $pointer + 1;
    }

    @return $pointer, if($minus and $result != null, $result * -1, $result);
}

/// Power function
/// @access private
/// @param {Number} $x - number
/// @param {Number} $n - power
/// @return {Number}

@function _pow($x, $n) {
    @if $n == 0 {
        @return 1;
    }
    $ret: 1;
    @if $n >= 0 {
        @for $i from 1 through $n {
            $ret: $ret * $x;
        }
    } @else {
        @for $i from $n to 0 {
            $ret: $ret / $x;
        }
    }

    @return $ret;
}

/// Cast a stringified number / stringified percentage into number type
/// @access private
/// @param {String} $string - JSON string
/// @return {Number} - unitless number or percentage
/// @require {function} _json-decode--number
@function _get-color-value($string) {
    $first: str-slice($string, 1, 1);

    // Pad <1 values with a leading 0
    @if $first == "." {
        $string: "0" + $string;
    }

    $last: str-slice($string, -1, -1);

    @return if(
        $last == "%",
        nth(_json-decode--number(str-slice($string, 1, -2), 2), 2) * 1%,
        nth(_json-decode--number($string, 2), 2)
    );
}

/// Cast a JSON encoded string into a rgb(a) color
/// @access private
/// @param {String} $string - JSON string
/// @return {Color | String} - string or rgb(a) color depending on the match
/// @require {function} _get-color-value
@function _from-rgb($string) {
    $string-lower: to-lower-case($string);
    $frags: ();
    $is-alpha: str-slice($string-lower, 4, 4) == "a";
    $start: str-index($string, "(");
    $length: str-length($string);

    @for $i from $start through $length {
        $token: str-slice($string-lower, $i, $i);
        @if $token == " " or $token == "	" {
            // @continue;
        } @else if $token == "(" or $token == "," {
            $frags: append($frags, "");
        } @else if $token == ")" {
            @if length($frags) != if($is-alpha, 4, 3) {
                @return $string;
            } // Parsing error
            $red: _get-color-value(nth($frags, 1));
            $green: _get-color-value(nth($frags, 2));
            $blue: _get-color-value(nth($frags, 3));

            @if not $red or not $green or not $blue {
                @return $string;
            }

            @if $is-alpha {
                @if length($frags) != 4 {
                    @return $string;
                } // No alpha channel found
                $alpha: _get-color-value(nth($frags, 4));
                @if not $alpha {
                    @return $string;
                } // Error parsing alpha channel
                @return rgba($red, $green, $blue, $alpha);
            }

            @return rgb($red, $green, $blue);
        } @else {
            $frags: set-nth($frags, length($frags), nth($frags, length($frags)) + $token);
        }
    }

    @return $string;
}

/// Cast a JSON encoded string into a hsl(a) color
/// @access private
/// @param {String} $string - JSON string
/// @return {Color | String} - string or hsl(a) color, depending on the match
/// @require {function} _get-color-value
@function _from-hsl($string) {
    $frags: ();
    $string-lower: to-lower-case($string);
    $is-alpha: str-slice($string-lower, 4, 4) == "a";
    $length: str-length($string);
    $start: str-index($string, "(");

    @for $i from $start through $length {
        $token: str-slice($string-lower, $i, $i);
        @if $token == " " or $token == "	" {
            // @continue;
        } @else if $token == "(" or $token == "," {
            $frags: append($frags, "");
        } @else if $token == ")" {
            @if length($frags) != if($is-alpha, 4, 3) {
                @return $string;
            } // Parsing error
            $hue: _get-color-value(nth($frags, 1));
            $saturation: _get-color-value(nth($frags, 2));
            $lightness: _get-color-value(nth($frags, 3));

            @if not $hue or not $saturation or not $lightness {
                @return $string;
            }

            @if $is-alpha {
                @if length($frags) != 4 {
                    @return $string;
                } // No alpha channel found
                $alpha: _get-color-value(nth($frags, 4));
                @if not $alpha {
                    @return $string;
                } // Error parsing alpha channel
                @return hsla($hue, $saturation, $lightness, $alpha);
            }

            @return hsl($hue, $saturation, $lightness);
        } @else {
            $frags: set-nth($frags, length($frags), nth($frags, length($frags)) + $token);
        }
    }

    @return $string;
}

/// Cast a JSON encoded string into a hexadecimal color
/// @access private
/// @param {String} $string - JSON string
/// @return {Color | String} - string or hex color depending on the match
/// @require {function} _hex-to-dec
@function _from-hex($string) {
    $string-lower: to-lower-case($string);
    $r: "";
    $g: "";
    $b: "";
    $hex: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9" "a" "b" "c" "d" "e" "f";
    $length: str-length($string);
    $max: if($length == 4, 1, 2);

    // Check for length accuracy
    @if $length != 4 and $length != 7 {
        @return $string;
    }

    // Loop from the second character (omitting #)
    @for $i from 2 through $length {
        $c: str-slice($string-lower, $i, $i);

        // If wrong character, return
        @if index($hex, $c) == null {
            @return $string;
        }

        @if str-length($r) < $max {
            $r: $r + $c;
        } @else if str-length($g) < $max {
            $g: $g + $c;
        } @else if str-length($b) < $max {
            $b: $b + $c;
        }
    }

    @if $length == 4 {
        $r: $r + $r;
        $g: $g + $g;
        $b: $b + $b;
    }

    @return rgb(_hex-to-dec($r), _hex-to-dec($g), _hex-to-dec($b));
}

/// Parses a JSON encoded string to see if it's a CSS color
/// @access private
/// @param {String} $string - JSON string
/// @return {Color | String} - string or number, depending on the match
/// @require {function} _from-hex
/// @require {function} _from-rgb
/// @require {function} _from-hsl
@function _color($string) {
    @if type-of($string) == "color" {
        @return $string;
    }

    $string-lower: to-lower-case($string);
    $colors: transparent black silver gray white maroon red purple fuchsia green lime olive yellow navy blue teal aqua
        aliceblue antiquewhite aqua aquamarine azure beige bisque black blanchedalmond blue blueviolet brown burlywood
        cadetblue chartreuse chocolate coral cornflowerblue cornsilk crimson cyan darkblue darkcyan darkgoldenrod
        darkgray darkgreen darkgrey darkkhaki darkmagenta darkolivegreen darkorange darkorchid darkred darksalmon
        darkseagreen darkslateblue darkslategray darkslategrey darkturquoise darkviolet deeppink deepskyblue dimgray
        dimgrey dodgerblue firebrick floralwhite forestgreen fuchsia gainsboro ghostwhite gold goldenrod gray green
        greenyellow grey honeydew hotpink indianred indigo ivory khaki lavender lavenderblush lawngreen lemonchiffon
        lightblue lightcoral lightcyan lightgoldenrodyellow lightgray lightgreen lightgrey lightpink lightsalmon
        lightseagreen lightskyblue lightslategray lightslategrey lightsteelblue lightyellow lime limegreen linen magenta
        maroon mediumaquamarine mediumblue mediumorchid mediumpurple mediumseagreen mediumslateblue mediumspringgreen
        mediumturquoise mediumvioletred midnightblue mintcream mistyrose moccasin navajowhite navy oldlace olive
        olivedrab orange orangered orchid palegoldenrod palegreen paleturquoise palevioletred papayawhip peachpuff peru
        pink plum powderblue purple red rosybrown royalblue saddlebrown salmon sandybrown seagreen seashell sienna
        silver skyblue slateblue slategray slategrey snow springgreen steelblue tan teal thistle tomato turquoise violet
        wheat white whitesmoke yellow yellowgreen;
    $keywords: ();

    // Filling $keywords with stringified color keywords
    @each $color in $colors {
        $keywords: append($keywords, $color + "");
    }

    // Deal with inherit keyword
    @if $string-lower == "inherit" {
        @return unquote($string);
    }

    @if index($keywords, $string-lower) {
        // Deal with color keywords
        @return nth($colors, index($keywords, $string-lower));
    } @else if str-slice($string-lower, 1, 1) == "#" {
        // Deal with hexadecimal triplets
        @return _from-hex($string);
    } @else if str-slice($string-lower, 1, 3) == "rgb" {
        // Deal with rgb(a) colors
        @return _from-rgb($string);
    } @else if str-slice($string-lower, 1, 3) == "hsl" {
        // Deal with hsl(a) colors
        @return _from-hsl($string);
    } @else {
        // Return string
        @return $string;
    }
}

//Border radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

//Transicion ease-in
@mixin property-transition($property, $time) {
    -moz-transition: $property $time ease-in;
    -o-transition: $property $time ease-in;
    -webkit-transition: $property $time ease-in;
    -ms-transition: $property $time ease-in;
    transition: $property $time ease-in;
}

//Transicion generalizada
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

//Transición ease-in de todo
@mixin fade-transition($time) {
    -moz-transition: all $time ease-in;
    -o-transition: all $time ease-in;
    -webkit-transition: all $time ease-in;
    -ms-transition: all $time ease-in;
    transition: all $time ease-in;
}

//Animaciones
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

//Transforms
@mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
}

@mixin transform-transition($args) {
    -webkit-transition: -webkit-transform $args;
    -moz-transition: -moz-transform $args;
    -ms-transition: -ms-transform $args;
    -o-transition: -o-transform $args;
    transition: transform $args;
}

// Imágenes svg con fallback png
$image-path: "/assets/img/" !default;
$fallback-extension: "png" !default;
@mixin background-image($name, $size: false) {
    background-image: url(#{$image-path}/#{$name}.svg);
    @if ($size) {
        background-size: $size;
    }
    .no-svg & {
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});
    }
}

// Botones unicolor
@mixin unicolor-button($color, $display: false, $padding: false, $icon_size: false) {
    background-color: mymix(white, $color, 70%);
    color: mymix(black, $color, 30%);
    @if ($display) {
        display: $display;
    } @else {
        display: inline-block;
    }
    @if ($padding) {
        padding: $padding;
    } @else {
        padding: 6px 8px;
    }
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    @include border-radius(3px);
    @include fade-transition(0.1s);
    .icon {
        color: $color;
        margin-right: 5px;
        @if ($icon_size) {
            font-size: $icon_size;
        }
    }
    &:hover {
        background-color: mymix(white, $color, 50%);
        color: mymix(black, $color, 50%);
        .icon {
            color: mymix(black, $color, 10%);
        }
    }
    &:active {
        -moz-box-shadow: inset 0 3px 6px mymix(black, $color, 30%, 0.4);
        -webkit-box-shadow: inset 0 3px 6px mymix(black, $color, 30%, 0.4);
        box-shadow: inset 0 3px 6px mymix(black, $color, 30%, 0.4);
    }
}

// Mixin para sacar los valores HSL
@mixin extract-hsl($var-name, $color) {
    --#{$var-name}-h: #{hue($color)};
    --#{$var-name}-s: #{saturation($color)};
    --#{$var-name}-l: #{lightness($color)};
    --#{$var-name}-r: #{red($color)};
    --#{$var-name}-g: #{green($color)};
    --#{$var-name}-b: #{blue($color)};
}

$PI: 3.141592653589793;

@function str-trim($string) {
    @if (str-slice($string, 1, 1) == " ") {
        @return str-trim(str-slice($string, 2));
    } @else if (str-slice($string, str-length($string), -1) == " ") {
        @return str-trim(str-slice($string, 1, -2));
    } @else {
        @return $string;
    }
}

@function extract-var($string) {
    $var: str-slice($string, 7, str-length($string) - 1);
    $param: false;
    $another_param_index: str-index(#{$var}, ",");

    @if $another_param_index != null {
        $param: str-trim(str-slice($var, $another_param_index + 1, str-length($var)));
        $var: str-slice($var, 1, $another_param_index - 1);
    }

    @return ($var, $param);
}

// función para manejar el mix para cuando sea una variable
@function mymix($color1, $color2, $percentage, $alpha: 1) {
    $diff: 100% - $percentage;
    $decimal: $percentage / 100%;
    $diff_decimal: $diff / 100%;

    @if str-index(#{$color1}, "var(--") == null and str-index(#{$color2}, "var(--") == null {
        @return rgba(mix($color1, $color2, $percentage), $alpha);
    } @else if str-index(#{$color1}, "var(--") != null and str-index(#{$color2}, "var(--") != null {
        $frags1: extract-var($color1);
        $var1: nth($frags1, 1);
        $param1: nth($frags1, 2);

        $var1-r: var(--#{$var1}-r);
        $var1-g: var(--#{$var1}-g);
        $var1-b: var(--#{$var1}-b);

        @if $param1 != false {
            @if str-index(#{$param1}, "var(--") != null {
                $frags: extract-var($param1);

                $another_var: nth($frags, 1);

                $var1-r: var(--#{$var1}-r, var(--#{$another_var}-r));
                $var1-g: var(--#{$var1}-g, var(--#{$another_var}-g));
                $var1-b: var(--#{$var1}-b, var(--#{$another_var}-b));
            } @else {
                $param1_color: _color($param1);

                $r1: red($param1_color);
                $g1: green($param1_color);
                $b1: blue($param1_color);

                $var1-r: var(--#{$var1}-r, #{$r1});
                $var1-g: var(--#{$var1}-g, #{$g1});
                $var1-b: var(--#{$var1}-b, #{$b1});
            }
        }

        $frags2: extract-var($color2);
        $var2: nth($frags2, 1);
        $param2: nth($frags2, 2);

        $var2-r: var(--#{$var2}-r);
        $var2-g: var(--#{$var2}-g);
        $var2-b: var(--#{$var2}-b);

        @if $param2 != false {
            @if str-index(#{$param2}, "var(--") != null {
                $frags: extract-var($param2);

                $another_var: nth($frags, 1);

                $var2-r: var(--#{$var2}-r, var(--#{$another_var}-r));
                $var2-g: var(--#{$var2}-g, var(--#{$another_var}-g));
                $var2-b: var(--#{$var2}-b, var(--#{$another_var}-b));
            } @else {
                $param2_color: _color($param2);

                $r2: red($param2_color);
                $g2: green($param2_color);
                $b2: blue($param2_color);

                $var2-r: var(--#{$var2}-r, #{$r2});
                $var2-g: var(--#{$var2}-g, #{$g2});
                $var2-b: var(--#{$var2}-b, #{$b2});
            }
        }

        @return rgba(
            calc(#{$var1-r} * #{$decimal} + #{$var2-r} * #{$diff_decimal}),
            calc(#{$var1-g} * #{$decimal} + #{$var2-g} * #{$diff_decimal}),
            calc(#{$var1-b} * #{$decimal} + #{$var2-b} * #{$diff_decimal}),
            #{$alpha}
        );
    } @else if str-index(#{$color1}, "var(--") != null {
        $frags1: extract-var($color1);
        $var1: nth($frags1, 1);
        $param1: nth($frags1, 2);

        $var1-r: var(--#{$var1}-r);
        $var1-g: var(--#{$var1}-g);
        $var1-b: var(--#{$var1}-b);

        @if $param1 != false {
            @if str-index(#{$param1}, "var(--") != null {
                $frags: extract-var($param1);

                $another_var: nth($frags, 1);

                $var1-r: var(--#{$var1}-r, var(--#{$another_var}-r));
                $var1-g: var(--#{$var1}-g, var(--#{$another_var}-g));
                $var1-b: var(--#{$var1}-b, var(--#{$another_var}-b));
            } @else {
                $param1_color: _color($param1);

                $r1: red($param1_color);
                $g1: green($param1_color);
                $b1: blue($param1_color);

                $var1-r: var(--#{$var1}-r, #{$r1});
                $var1-g: var(--#{$var1}-g, #{$g1});
                $var1-b: var(--#{$var1}-b, #{$b1});
            }
        }

        $r: red($color2);
        $g: green($color2);
        $b: blue($color2);

        @return rgba(
            calc(#{$var1-r} * #{$decimal} + #{$r} * #{$diff_decimal}),
            calc(#{$var1-g} * #{$decimal} + #{$g} * #{$diff_decimal}),
            calc(#{$var1-b} * #{$decimal} + #{$b} * #{$diff_decimal}),
            #{$alpha}
        );
    } @else if str-index(#{$color2}, "var(--") != null {
        $frags2: extract-var($color2);
        $var2: nth($frags2, 1);
        $param2: nth($frags2, 2);

        $var2-r: var(--#{$var2}-r);
        $var2-g: var(--#{$var2}-g);
        $var2-b: var(--#{$var2}-b);

        @if $param2 != false {
            @if str-index(#{$param2}, "var(--") != null {
                $frags: extract-var($param2);

                $another_var: nth($frags, 1);

                $var2-r: var(--#{$var2}-r, var(--#{$another_var}-r));
                $var2-g: var(--#{$var2}-g, var(--#{$another_var}-g));
                $var2-b: var(--#{$var2}-b, var(--#{$another_var}-b));
            } @else {
                $param2_color: _color($param2);

                $r2: red($param2_color);
                $g2: green($param2_color);
                $b2: blue($param2_color);

                $var2-r: var(--#{$var2}-r, #{$r2});
                $var2-g: var(--#{$var2}-g, #{$g2});
                $var2-b: var(--#{$var2}-b, #{$b2});
            }
        }

        $r: red($color1);
        $g: green($color1);
        $b: blue($color1);

        @return rgba(
            calc(#{$var2-r} * #{$diff_decimal} + #{$r} * #{$decimal}),
            calc(#{$var2-g} * #{$diff_decimal} + #{$g} * #{$decimal}),
            calc(#{$var2-b} * #{$diff_decimal} + #{$b} * #{$decimal}),
            #{$alpha}
        );
    }
}

@function mydarken($color, $percentage) {
    @if str-index(#{$color}, "var(--") == null {
        @return darken($color, $percentage);
    } @else {
        $frags: extract-var($color);
        $var: nth($frags, 1);
        $param: nth($frags, 2);

        $var-h: var(--#{$var}-h);
        $var-s: var(--#{$var}-s);
        $var-l: var(--#{$var}-l);

        @if $param != false {
            @if str-index(#{$param}, "var(--") != null {
                $frags2: extract-var($param);

                $another_var: nth($frags2, 1);

                $var-h: var(--#{$var}-h, var(--#{$another_var}-h));
                $var-s: var(--#{$var}-s, var(--#{$another_var}-s));
                $var-l: var(--#{$var}-l, var(--#{$another_var}-l));
            } @else {
                $another_param: _color($param);

                $h: hue($another_param);
                $s: saturation($another_param);
                $l: lightness($another_param);

                $var-h: var(--#{$var}-h, #{$h});
                $var-s: var(--#{$var}-s, #{$s});
                $var-l: var(--#{$var}-l, #{$l});
            }
        }

        @return hsl(#{$var-h}, #{$var-s}, calc(#{$var-l} - #{$percentage}));
    }
}

@function mylighten($color, $percentage) {
    @if str-index(#{$color}, "var(--") == null {
        @return lighten($color, $percentage);
    } @else {
        $frags: extract-var($color);
        $var: nth($frags, 1);
        $param: nth($frags, 2);

        $var-h: var(--#{$var}-h);
        $var-s: var(--#{$var}-s);
        $var-l: var(--#{$var}-l);

        @if $param != false {
            @if str-index(#{$param}, "var(--") != null {
                $frags2: extract-var($param);

                $another_var: nth($frags2, 1);

                $var-h: var(--#{$var}-h, var(--#{$another_var}-h));
                $var-s: var(--#{$var}-s, var(--#{$another_var}-s));
                $var-l: var(--#{$var}-l, var(--#{$another_var}-l));
            } @else {
                $another_param: _color($param);

                $h: hue($another_param);
                $s: saturation($another_param);
                $l: lightness($another_param);

                $var-h: var(--#{$var}-h, #{$h});
                $var-s: var(--#{$var}-s, #{$s});
                $var-l: var(--#{$var}-l, #{$l});
            }
        }

        @return hsl(#{$var-h}, #{$var-s}, calc(#{$var-l} + #{$percentage}));
    }
}

@function -math-exp-taylor-0($x, $steps) {
    $item: 1;
    $result: 1;

    @for $i from 1 to $steps {
        $item: $item * $x / $i;
        $result: $result + $item;
    }

    @return $result;
}

@function -math-ln-taylor-1($x, $steps) {
    $z: ($x - 1) / ($x + 1);

    $power: $z;
    $result: $z;

    @for $i from 1 to $steps {
        $power: $power * $z * $z;
        $result: $result + $power / (2 * $i + 1);
    }

    @return 2 * $result;
}

@function -math-sin-taylor-0($x, $steps) {
    $item: $x;
    $result: $x;

    @for $i from 1 to $steps {
        $item: -$item * $x * $x / (2 * $i) / (2 * $i + 1);
        $result: $result + $item;
    }

    @return $result;
}

@function -math-pow-int($base, $exponent) {
    @if $exponent < 0 {
        @return 1 / -math-pow-int($base, -$exponent);
    } @else if $exponent == 0 {
        @return 1;
    } @else if $exponent == 1 {
        @return $base;
    } @else {
        $exp: floor($exponent / 2);
        $pow: -math-pow-int($base, $exp);
        @if $exp * 2 == $exponent {
            @return $pow * $pow;
        } @else {
            @return $pow * $pow * $base;
        }
    }
}

@function -math-log-approx($x) {
    @if $x <= 0 {
        @error "cannot calculate log of #{$x}";
    } @else if $x >= 1 {
        // choose the smaller option (-1) because it yield better
        // results in ln().
        @return str-length(inspect(round($x))) - 1;
    } @else {
        @return -1 * str-length(inspect(round(1 / $x)));
    }
}

@function ln($x, $steps: 32) {
    $ln10: 2.302585092994046;
    $approx: -math-log-approx($x);
    // $y is in range [1, 10]
    $y: $x / -math-pow-int(10, $approx);
    @return $approx * $ln10 + -math-ln-taylor-1($y, $steps);
}

@function pow($x, $exponent, $steps: 32) {
    $exp1: round($exponent);
    $exp2: $exponent - $exp1;
    $pow1: -math-pow-int($x, $exp1);
    @if $exp2 == 0 {
        @return $pow1;
    } @else {
        $y: ln($x, $steps) * $exp2;
        $pow2: -math-exp-taylor-0($y, $steps);
        @return $pow1 * $pow2;
    }
}

@function lum_factor($v) {
    $v: $v/255;
    @if ($v <= 0.03928) {
        @return $v / 12.92;
    } @else {
        @return pow(($v + 0.055) / 1.055, 2.4);
    }
}

@function luminanace($color) {
    $vr: lum_factor(red($color));
    $vg: lum_factor(green($color));
    $vb: lum_factor(blue($color));

    @return $vr * 0.2126 + $vg * 0.7152 + $vb * 0.0722;
}

@function contrast($rgb1, $rgb2) {
    $lum1: luminanace($rgb1);
    $lum2: luminanace($rgb2);
    $brightest: max($lum1, $lum2);
    $darkest: min($lum1, $lum2);
    @return ($brightest + 0.05) / ($darkest + 0.05);
}

@function contrast-checker($foreground, $background) {
    @if contrast($foreground, $background) < 4.5 {
        @return "false";
    } @else {
        @return "true";
    }
}

@mixin active-darken-btn-style($asignatura-color) {
    $btn-colour: $asignatura-color;
    @while (contrast-checker($btn-colour, #ffffff) == "false") {
        $btn-colour: darken($btn-colour, 1%);
        @if (contrast-checker($btn-colour, #ffffff) == "true") {
            background-color: $btn-colour !important;
        }
    }
}

@function luma($c) {
    $-local-red: red(rgba($c, 1));
    $-local-green: green(rgba($c, 1));
    $-local-blue: blue(rgba($c, 1));
    @return (0.2126 * $-local-red + 0.7152 * $-local-green + 0.0722 * $-local-blue) / 255;
}

// Escoge el color entre $c1 y $c2 que contrasta con el fondo $bg. No se puede usar con var(--color) de css
@function pick-visible-color($bg, $c1, $c2) {
    $bg-luma: luma($bg);
    $c1-luma: luma($c1);
    $c2-luma: luma($c2);
    $c1-diff: abs($bg-luma - $c1-luma);
    $c2-diff: abs($bg-luma - $c2-luma);

    @if $c1-diff > $c2-diff {
        @return $c1;
    } @else {
        @return $c2;
    }
}

// Retorna blanco o negro dependiendo del fondo $bg para determinar el contraste. Se usa con var(--color) de css.
@function get-contrast($bg, $threshold: 60%) {
    $i: str-index($bg, ",");
    // caso en que no haya segundo parametro
    @if not($i) {
        $var: str-slice($bg, 7, str-length($bg) - 1);
        @return hsl(0, 0%, calc((var(--#{$var}-l) - #{$threshold}) * -100));
    } @else {
        $var: str-slice($bg, 7, $i - 1);
        $fallback: str-slice($bg, $i + 8, str-length($bg) - 2);
        @return hsl(0, 0%, calc((var(--#{$var}-l, var(--#{$fallback}-l)) - #{$threshold}) * -100));
    }
}
